import React, { FC } from 'react';
import { Project } from '../../types/project';
import { PhotoType } from '../../types/photo';
import { ProjectThumbnails } from './ProjectThumbnails';
import { Tabs } from '../../components/Tabs';
import {
  RedDot,
  ProjectName,
  ProjectToggle,
  ProjectContainer,
  Toggle
} from './styles';
import { Icon } from '../../components/Icons';

const NEW: PhotoType = 'NEW';
const ALL: PhotoType = 'ALL';

interface Props {
  project: Project;
  togglePhoto: (projectId: string, photoId: string, type: PhotoType) => void;
  getPhotosForTimelapse: (project: Project, type: PhotoType) => void;
  isLoading: boolean;
  activeProject: string;
  setActiveProject: React.Dispatch<any>;
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}

export const ProjectPhotos: FC<Props> = ({
  project,
  togglePhoto,
  getPhotosForTimelapse,
  isLoading,
  activeProject,
  setActiveProject,
  selectedTab,
  setSelectedTab
}) => {
  const _renderThumbnails = (type: PhotoType) => (
    <ProjectThumbnails
      project={project}
      onTogglePhoto={togglePhoto}
      onCreateTimelapse={() => getPhotosForTimelapse(project, type)}
      isLoading={isLoading}
      type={type}
    />
  );
  return (
    <ProjectContainer key={project.id}>
      <ProjectToggle
        onClick={() => {
          setActiveProject(
            activeProject !== project.slug ? project.slug : undefined
          );
          setSelectedTab(0);
        }}
      >
        <ProjectName active={project.slug === activeProject}>
          {project.name}
          {project.newPhotos && project.newPhotos.length > 0 && (
            <RedDot>{project.newPhotos.length}</RedDot>
          )}
          <Toggle>
            {project.slug === activeProject ? (
              <Icon type="arrowUp" />
            ) : (
              <Icon type="arrowDown" />
            )}
          </Toggle>
        </ProjectName>
      </ProjectToggle>
      {project.slug === activeProject && (
        <Tabs
          selectedIndex={selectedTab}
          updateSelectedTabIndex={setSelectedTab}
          items={[
            {
              id: NEW,
              title: `Nieuwe foto's (${project.newPhotos &&
                project.newPhotos.filter(p => p.photo).length})`,
              content:
                project.newPhotos && project.newPhotos.length > 0 ? (
                  <>
                    <p>
                      Geef hieronder aan welke nieuwe foto's je wilt gebruiken
                      en maak eventueel een nieuwe timelapse
                    </p>
                    {_renderThumbnails(NEW)}
                  </>
                ) : (
                  <p>Er zijn voor dit project geen nieuwe foto's</p>
                )
            },
            {
              id: ALL,
              title: `Huidige timelapse foto's (${project.allPhotos &&
                project.allPhotos.filter(p => p.photo).length})`,
              content:
                project.allPhotos && project.allPhotos.length > 0 ? (
                  <>
                    <p>
                      Zet hieronder de foto's uit die je wilt verwijderen en
                      maak een nieuwe timelapse
                    </p>
                    {_renderThumbnails(ALL)}
                  </>
                ) : (
                  <p>Er is voor dit project nog geen timelapse</p>
                )
            }
          ]}
        />
      )}
    </ProjectContainer>
  );
};
