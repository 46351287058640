import React, { Fragment, useEffect, useReducer, FC, Reducer } from 'react';
import { fetchProject } from '../../requests/projects';
import { uploadImage } from '../../requests/upload';
import { storeImage } from '../../requests/photo';
import { storeEmailAddress } from '../../requests/natuurgekkie';
import { RouteComponentProps } from 'react-router';
import { reducer, initState, ProjectState } from '../../reducers/project';
import { ActionTypes } from '../../actions/actionTypes';
import { ProjectActions } from '../../actions/project';
import { ImageSelected } from './ImageSelected';
import { ProjectDetails } from './ProjectDetails';
import { ThankYouPage } from './ThankYouPage';

interface Props {
  slug: string;
  match?: {
    params: {
      projectSlug: string;
    };
  };
}

export const Project: FC<Props & RouteComponentProps> = ({
  match,
  location
}) => {
  
  // TODO: make better RouteProps...
  const [state, dispatch] = useReducer<Reducer<ProjectState, ProjectActions>>(
    reducer,
    initState
  );

  const { projectSlug } = match && match.params;

  useEffect(() => {
    fetchProject(dispatch, projectSlug);
  }, [location, projectSlug]);

  useEffect(() => {
    if (state.uploadImageSuccess && state.uploadedImageUrl && state.uploadedImageId && state.project) {
      storeImage(
        dispatch,
        state.project,
        state.uploadedImageUrl,
        state.uploadedImageId
      );
    }
  }, [state.uploadImageSuccess, state.uploadedImageUrl, state.uploadedImageId, state.project]);

  return (
    <Fragment>
      {state.storeImageSuccess ? (
        <ThankYouPage
          storeEmailAddress={email => storeEmailAddress(dispatch, email)}
          storingEmailAddress={state.storingEmailAddress}
          storeEmailAddressError={state.storeEmailAddressError}
          storeEmailAddressSuccess={state.storeEmailAddressSuccess}
          projectDescription={state.project && state.project.description}
        />
      ) : state.selectedImage ? (
        <ImageSelected
          selectedImage={state.selectedImage}
          handleSubmitImage={() =>
            state.selectedImage &&
            uploadImage(dispatch, state.selectedImage, state.project)
          }
          handleClearImage={() => dispatch({ type: ActionTypes.CLEAR_IMAGE })}
          uploadingImage={state.uploadingImage}
        />
      ) : (
        <ProjectDetails
          project={state.project}
          handleImageSelect={(file: File) =>
            dispatch({ type: ActionTypes.SELECT_IMAGE, payload: file })
          }
          projectIsLoading={state.projectIsLoading}
          projectNotFound={state.projectNotFound}
          projectHasError={state.projectHasError}
        />
      )}
    </Fragment>
  );
};
