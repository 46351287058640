import styled, { css } from 'styled-components';

export const Button = styled.button<{ variant?: 'primary' | 'secondary' }>`
  background: ${({ variant }) =>
    variant === 'secondary' ? '#C4DCBC' : '#84BDAB'};
  border: none;
  width: 14rem;
  border-radius: 0.25rem;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  font-size: inherit;
  margin-bottom: 1rem;
  padding: 1rem 3rem;
  transition: all 0.3s;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
    background: #8aa5b6;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #eee;
      &:hover {
        cursor: default;
        background: #eee;
      }
    `};
`;

export const OutlineButton = styled(Button)`
  background: none;
  border: 1px solid #8aa5b6;
  margin-top: 2rem;
  color: #8aa5b6;
  font-weight: 400;
  width: 100%;
  :hover {
    border: 1px solid #84bdab;
    background: #f3f6f7;
    color: #84bdab;
  }
`;

export const LargeButton = styled(Button)`
  width: 100%;
`;
