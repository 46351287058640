import React from 'react';

export default (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 14" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-17.000000, -23.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <path d="M24,28.5857864 L28.9497475,23.636039 L30.363961,25.0502525 L25.4142136,30 L30.363961,34.9497475 L28.9497475,36.363961 L24,31.4142136 L19.0502525,36.363961 L17.636039,34.9497475 L22.5857864,30 L17.636039,25.0502525 L19.0502525,23.636039 L24,28.5857864 Z" />
      </g>
    </g>
  </svg>
);
