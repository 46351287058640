import { Reducer } from 'react';
import { ProjectActions } from '../actions/project';
import { ActionTypes } from '../actions/actionTypes';
import { Project } from '../types/project';

export interface ProjectState {
  project?: Project;
  projectIsLoading?: boolean;
  projectHasError?: boolean;
  projectNotFound?: boolean;
  selectedImage?: File;
  storeImageLoading?: boolean;
  storeImageError?: boolean;
  storeImageSuccess?: boolean;
  uploadedImageUrl?: string;
  uploadedImageId?: number;
  uploadImageSuccess?: boolean;
  storingEmailAddress?: boolean;
  storeEmailAddressSuccess?: boolean;
  storeEmailAddressError?: boolean;
  uploadingImage?: boolean;
}

export const initState: ProjectState = {
  project: undefined,
  projectIsLoading: false,
  projectHasError: false,
  projectNotFound: false,
  selectedImage: undefined,
  storeImageLoading: false,
  uploadedImageUrl: undefined,
  uploadedImageId: undefined,
};

export const reducer: Reducer<ProjectState, ProjectActions> = (state, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PROJECT:
      return { ...state, projectIsLoading: true };
    case ActionTypes.FETCH_PROJECT_SUCCESS:
      const project = action.payload[0];
      return { ...state, project, projectIsLoading: false };
    case ActionTypes.FETCH_PROJECT_ERROR:
      return { ...state, projectIsLoading: false, projectHasError: true };
    case ActionTypes.FETCH_PROJECT_NOT_FOUND:
      return { ...state, projectIsLoading: false, projectNotFound: true };

    case ActionTypes.SELECT_IMAGE:
      return {
        ...state,
        selectedImage: action.payload,
      };
    case ActionTypes.CLEAR_IMAGE:
      return {
        ...state,
        selectedImage: undefined,
      };

    // uploading images
    case ActionTypes.UPLOAD_IMAGE:
      return {
        ...state,
        uploadingImage: true,
        uploadImageError: false,
      };

    case ActionTypes.UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        uploadingImage: false,
        uploadImageError: true,
      };

    case ActionTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadingImage: false,
        uploadImageSuccess: true,
        uploadedImageUrl: action.payload.imageUrl,
        uploadedImageId: action.payload.id,
        selectedImage: undefined,
      };

    // storing images
    case ActionTypes.STORE_IMAGE:
      console.log('dispatch: STORE_IMAGE');
      return {
        ...state,
        storeImageLoading: true,
        storeImageError: false,
      };
    case ActionTypes.STORE_IMAGE_SUCCESS:
      console.log('dispatch: STORE_IMAGE_SUCCESS');
      return {
        ...state,
        storeImageLoading: false,
        storeImageSuccess: true,
        uploadedImageUrl: undefined,
        uploadedImageId: undefined,
      };
    case ActionTypes.STORE_IMAGE_ERROR:
      console.log('dispatch: STORE_IMAGE_ERROR');
      return {
        ...state,
        storeImageLoading: false,
        storeImageError: true,
        uploadedImageUrl: undefined,
        uploadedImageId: undefined,
      };

    case ActionTypes.STORE_EMAIL_ADDRESS:
      return {
        ...state,
        storingEmailAddress: true,
      };
    case ActionTypes.STORE_EMAIL_ADDRESS_ERROR:
      return {
        ...state,
        storingEmailAddress: false,
        storeEmailAddressError: true,
      };
    case ActionTypes.STORE_EMAIL_ADDRESS_SUCCESS:
      return {
        ...state,
        storingEmailAddress: false,
        storeEmailAddressSuccess: true,
      };

    default:
      return state;
  }
};
