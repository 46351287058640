interface IFTT {
  project: string;
  url: string;
}

export const IFTThook = async ({ project, url }: IFTT) => {
  try {
    const response = await fetch(
      `https://maker.ifttt.com/trigger/new_timpel_photo/with/key/c9_tuWCjzjCcO8vlpmo7pS`,
      {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `value1=${project}&value2=${url}`,
      }
    );
    return response.status === 500 ? { error: true } : { status: response.status };
  } catch (e) {
    return { error: true };
  }
};
