import React, { FC, ReactNode } from 'react';
import { TabsWrapper, TabList, Tab, TabPanel } from './styles';
import { PhotoType } from '../../types/photo';

interface Tab {
  title: ReactNode;
  content: ReactNode;
  id: PhotoType;
}

interface Props {
  items: ReadonlyArray<Tab>;
  selectedIndex: number;
  updateSelectedTabIndex?: (newIndex: number) => void;
}

export const Tabs: FC<Props> = ({
  items,
  selectedIndex,
  updateSelectedTabIndex
}) => {
  return (
    <TabsWrapper>
      <TabList role="tablist">
        {items.map((item, i) => (
          <Tab
            role="tab"
            key={item.id}
            aria-selected={i === selectedIndex}
            selected={i === selectedIndex}
            onClick={() => updateSelectedTabIndex && updateSelectedTabIndex(i)}
          >
            {item.title}
          </Tab>
        ))}
      </TabList>
      <TabPanel role="tabpanel">
        {items[selectedIndex] && items[selectedIndex].content}
      </TabPanel>
    </TabsWrapper>
  );
};
