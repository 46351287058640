import React, { useReducer, useEffect, FC, Reducer } from 'react';
import { PageTitle } from '../../components/PageTitle';
import { Icon } from '../../components/Icons';
import { Overlay } from '../../components/Overlay';
import { reducer, initState, ProjectsState } from '../../reducers/projects';
import { fetchAllProjects } from '../../requests/projects';
import { ProjectsActions } from '../../actions/projects';
import {
  ProjectLinks,
  ProjectLink,
  Name,
  ShortDescription,
  LogoElement,
  Info
} from './styles';

export const Projects: FC = () => {
  const [state, dispatch] = useReducer<Reducer<ProjectsState, ProjectsActions>>(
    reducer,
    initState
  );
  useEffect(() => {
    fetchAllProjects(dispatch);
  }, []);
  return (
    <>
      {state.isLoading && <Overlay message="Projecten worden opgehaald..." />}
      {state.hasError && <PageTitle>Geen projecten gevonden.</PageTitle>}
      {state.projects && state.projects.length > 0 && (
        <>
          <PageTitle>Alle projecten</PageTitle>
          <ProjectLinks>
            {state.projects
              .filter(p => process.env.NODE_ENV === 'development' || p.enabled)
              .map((project: any, index: number) => (
                <ProjectLink to={`/${project.slug}`} key={index}>
                  <LogoElement>
                    <Icon type="element" />
                  </LogoElement>
                  <Info>
                    <Name>{project.name}</Name>
                    <ShortDescription>{project.description}</ShortDescription>
                  </Info>
                </ProjectLink>
              ))}
          </ProjectLinks>
        </>
      )}
    </>
  );
};
