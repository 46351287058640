import showdown from 'showdown';
import { ContentActions } from '../actions/content';
import { ActionTypes } from '../actions/actionTypes';
import { ContentPage } from '../types/content';
import { Reducer } from 'react';

const converter = new showdown.Converter();

export interface ContentState {
  pageIsLoading: boolean;
  pageHasError: boolean;
  page?: ContentPage;
}

export const initState: ContentState = {
  pageIsLoading: false,
  pageHasError: false
};

export const reducer: Reducer<ContentState, ContentActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_CONTENT:
      return { ...state, pageIsLoading: true };
    case ActionTypes.FETCH_CONTENT_ERROR:
      return { ...state, pageIsLoading: false, pageHasError: true };
    case ActionTypes.FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        pageIsLoading: false,
        pageHasError: false,
        page: {
          title: action.payload.title,
          content: converter.makeHtml(action.payload.content)
        }
      };
    default:
      return state;
  }
};
