import { ProjectActions } from '../actions/project';
import { Project } from '../types/project';
import { ActionTypes } from '../actions/actionTypes';
import { api } from '../utils/api';
import { logger } from '../utils/logger';
import { notifySlack } from './functions';
import { IFTThook } from '../utils/iftt';

export const uploadImage = async (
  dispatch: React.Dispatch<ProjectActions>,
  selectedImage?: File,
  project?: Project
) => {
  dispatch({ type: ActionTypes.UPLOAD_IMAGE });
  const formData = new FormData();
  formData.append('path', project ? `${project.slug}` : 'unknown-project');
  formData.append('files', selectedImage || '');
  const response = await api({
    path: `upload`,
    method: 'POST',
    formData: formData
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.UPLOAD_IMAGE_ERROR });
    logger.error(
      `Uploading new photo for project "${project && project.name}" failed! Error: ${
        response.status
      }, ${response.error}`
    );
  } else {
    console.log('DATA: ', response.data)
    dispatch({
      type: ActionTypes.UPLOAD_IMAGE_SUCCESS,
      payload: { imageUrl: response.data[0].url, id: response.data[0].id },
    });
    logger.info(
      `Photo with ID "${response.data[0].id}" for project "${project &&
        project.name}" successfully uploaded to ${response.data[0].url}`
    );
    notifySlack(
      project ? project.slug : 'unknown-project',
      response.data[0].url
    );
    IFTThook({
      project: project ? project.slug : 'unknown-project',
      url: response.data[0].url
    });
  }
};
