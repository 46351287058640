import React from 'react';

export default (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 14" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-996.000000, -694.000000)" fill="currentColor">
        <g transform="translate(996.000000, 694.000000)">
          <polygon points="0 0 0 0.695652174 16 0.695652174 16 0" />
        </g>
      </g>
    </g>
  </svg>
);
