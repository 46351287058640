import { logger } from './logger';

interface Api {
  path: string;
  method: 'GET' | 'POST' | 'UPLOAD' | 'PUT';
  body?: any;
  headers?: {};
  formData?: FormData;
}

export const api = async ({ path, method, body, headers, formData }: Api) => {
  logger.info(`${method} '${path}'`);
  const host = path.startsWith('assemblies')
    ? process.env.REACT_APP_TRANSLOADIT_API_URL
    : path.startsWith('functions')
    ? process.env.REACT_APP_FUNCTIONS_URL
    : process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(`${host}/${path}`, {
      method,
      headers: !formData
        ? headers || {
            'Content-Type': 'application/json'
          }
        : {},
      body:
        body && !formData
          ? path === 'assemblies'
            ? `params=${encodeURI(JSON.stringify(body))}`
            : JSON.stringify(body)
          : formData
          ? formData
          : undefined
    });
    const contentType = response.headers.get('content-type');
    const data =
      contentType &&
      (contentType.indexOf('application/json') !== -1 ||
        contentType.indexOf('application/x-www-form-urlencoded') !== -1)
        ? await response.json()
        : await response.text();
    return response.status === 500
      ? { error: true }
      : { status: response.status, data };
  } catch (e) {
    logger.error(`Error while ${method} '${path}'`);
    return { error: true };
  }
};
