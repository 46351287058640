import { AuthenticateActions } from '../actions/authenticate';
import { ActionTypes } from '../actions/actionTypes';
import { Reducer } from 'react';

export interface AuthenticateState {
  authenticated: boolean;
  auth?: {
    jwt: string;
    role: string;
    userName: string;
  };
}

export const initState: AuthenticateState = {
  authenticated: false
};

export const reducer: Reducer<AuthenticateState, AuthenticateActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return { ...state };
    case ActionTypes.LOGIN_ERROR:
      return { ...state, authenticated: false };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        auth: {
          jwt: action.payload.jwt,
          role: action.payload.user.role.type,
          userName: action.payload.user.username
        }
      };
    default:
      return state;
  }
};
