// import { TransloaditAssembly } from '../types/timelapse';
import { Photo } from '../types/photo';
import { ActionTypes } from '../actions/actionTypes';
import { TimelapseActions } from '../actions/timelapse';

export interface TimelapseState {
  assembly?: any;
  photosToProcess?: ReadonlyArray<Photo>;
  projectSlugToProcess?: string;
  projectIdToProcess?: string;
  isLoading: boolean;
  hasError: boolean;
  timelapseIsReady?: boolean;
}

export const reducer: React.Reducer<TimelapseState, TimelapseActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionTypes.CREATE_TIMELAPSE:
      return { ...state };
    case ActionTypes.CREATE_TIMELAPSE_ERROR:
      return { ...state, isLoading: false, hasError: true };
    case ActionTypes.CREATE_TIMELAPSE_SUCCESS:
      return {
        ...state,
        hasError: false,
        assembly: action.payload
      };
    case ActionTypes.FETCH_TIMELAPSE_PHOTOS:
      return { ...state, isLoading: true };
    case ActionTypes.FETCH_TIMELAPSE_PHOTOS_ERROR:
      return { ...state, isLoading: false, hasError: true };
    case ActionTypes.FETCH_TIMELAPSE_PHOTOS_SUCCESS:
      return {
        ...state,
        photosToProcess: action.payload.photos.filter(photo => photo.photo),
        projectIdToProcess: action.payload.projectId,
        projectSlugToProcess: action.payload.projectSlug
      };
    case ActionTypes.FETCH_ASSEMBLY_STATUS_SUCCESS:
      return {
        ...state,
        assembly: action.payload
      };
    case ActionTypes.CREATE_TIMELAPSE_READY:
      return {
        ...state,
        isLoading: false,
        timelapseIsReady: true,
        photosToProcess: []
      };

    default:
      return state;
  }
};

export const initState: TimelapseState = {
  isLoading: false,
  hasError: false
};
