import React, { useReducer, useEffect, FC, Reducer } from 'react';
import { Player, BigPlayButton } from 'video-react';
import { RouteComponentProps } from 'react-router-dom';
import { PageTitle } from '../../components/PageTitle';
import { Overlay } from '../../components/Overlay';
import { OutlineButton } from '../../components/Button';
import { Content, VideoWrapper, SubTitle } from './styles';
import {
  reducer as contentReducer,
  initState as contentInitState,
  ContentState
} from '../../reducers/content';
import {
  reducer as projectsReducer,
  initState as projectsInitState,
  ProjectsState
} from '../../reducers/projects';
import { ContentActions } from '../../actions/content';
import { ProjectsActions } from '../../actions/projects';
import { fetchContent } from '../../requests/pages';
import { fetchAllProjects } from '../../requests/projects';
import '../../../node_modules/video-react/dist/video-react.css';
import { useRandomTimelapse } from './useRandomTimelapse';

interface Props {
  slug: string;
  cookies?: any;
}

export const ContentPage: FC<RouteComponentProps & Props> = ({
  slug,
  history
}) => {
  const [content, contentDispatch] = useReducer<
    Reducer<ContentState, ContentActions>
  >(contentReducer, contentInitState);
  const [projects, projectsDispatch] = useReducer<
    Reducer<ProjectsState, ProjectsActions>
  >(projectsReducer, projectsInitState);
  const randomTimelapse = useRandomTimelapse(projects);
  useEffect(() => {
    fetchContent(contentDispatch, slug);
  }, [slug]);
  useEffect(() => {
    fetchAllProjects(projectsDispatch);
  }, []);
  return (
    <>
      {content.pageIsLoading && <Overlay message="Pagina wordt geladen..." />}
      {content.pageHasError && <PageTitle>Pagina niet gevonden.</PageTitle>}
      {content.page && (
        <>
          {slug === 'about' && (
            <VideoWrapper>
              <Player src={randomTimelapse && randomTimelapse.url}>
                <BigPlayButton position="center" />
              </Player>
              <SubTitle>{randomTimelapse && randomTimelapse.title}</SubTitle>
              <OutlineButton onClick={() => history.push('/projects')}>
                Bekijk alle projecten
              </OutlineButton>
            </VideoWrapper>
          )}

          <PageTitle>{content.page.title}</PageTitle>
          <Content dangerouslySetInnerHTML={{ __html: content.page.content }} />
        </>
      )}
    </>
  );
};
