import { ContentActions } from '../actions/content';
import { ActionTypes } from '../actions/actionTypes';
import { api } from '../utils/api';
import { logger } from '../utils/logger';

export const fetchContent = async (
  dispatch: React.Dispatch<ContentActions>,
  slug: string
) => {
  dispatch({ type: ActionTypes.FETCH_CONTENT });
  const response = await api({
    path: `pages?slug=${slug}&_limit=1`,
    method: 'GET'
  });
  if (
    response.status !== 200 ||
    response.error ||
    (response.data && response.data.length <= 0)
  ) {
    dispatch({ type: ActionTypes.FETCH_CONTENT_ERROR });
    logger.error(`Page with slug '${slug}' not found`);
  } else {
    dispatch({
      type: ActionTypes.FETCH_CONTENT_SUCCESS,
      payload: response.data[0]
    });
  }
};
