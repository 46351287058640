import { Photo } from '../types/photo';

export const transloaditConfig = {
  auth: {
    key: process.env.REACT_APP_TRANSLOADIT_KEY || ''
  },
  template_id: process.env.REACT_APP_TRANSLOADIT_TEMPLATE_ID || '',
  fields: {
    timelapseFolder: 'timelapse'
  }
};

export const transloaditStatus = {
  ASSEMBLY_EXECUTING: 'ASSEMBLY_EXECUTING',
  ASSEMBLY_UPLOADING: 'ASSEMBLY_UPLOADING',
  ASSEMBLY_COMPLETED: 'ASSEMBLY_COMPLETED'
};

export const transloaditOptions = (
  project: string,
  photosToProcess: ReadonlyArray<Photo>
) => {
  return {
    ...transloaditConfig,
    steps: {
      photos: {
        robot: '/http/import',
        url: photosToProcess.filter(p => p.photo).map(p => p.photo.url)
      },
      merged: {
        use: {
          steps: [
            {
              name: 'photos',
              as: 'image'
            }
          ]
        }
      }
    },
    fields: {
      ...transloaditConfig.fields,
      project,
      duration: photosToProcess.length / 2,
      framerate: '2'
    }
  };
};
