import React, {
  FC,
  Fragment,
  useReducer,
  Reducer,
  useEffect,
  useState
} from 'react';
import { ProjectPhotos } from './ProjectPhotos';
import { PageTitle } from '../../components/PageTitle';
import { Overlay } from '../../components/Overlay';

import {
  TimelapseState,
  reducer as timelapseReducer,
  initState as timelapseInitState
} from '../../reducers/timelapse';
import {
  ProjectsState,
  reducer as projectsReducer,
  initState as projectsInitState
} from '../../reducers/projects';

import { ActionTypes } from '../../actions/actionTypes';
import { TimelapseActions } from '../../actions/timelapse';
import { ProjectsActions } from '../../actions/projects';

import { createTimelapse, fetchAssemblyStatus } from '../../requests/timelapse';
import { fetchAllProjects, updateProject } from '../../requests/projects';
import {
  getNewPhotos,
  getAllPhotos,
  updatePhotoLabels,
  fetchTimelapsePhotos
} from '../../requests/photo';

import { Project } from '../../types/project';
import { PhotoType } from '../../types/photo';

import { transloaditStatus, transloaditOptions } from '../../utils/transloadit';
import { Projects } from './styles';

export const Admin: FC<{ userName: string }> = ({ userName }) => {
  const [activeProject, setActiveProject] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [timelapse, dispatchTimelapse] = useReducer<
    Reducer<TimelapseState, TimelapseActions>
  >(timelapseReducer, timelapseInitState);
  const [projects, dispatchProjects] = useReducer<
    Reducer<ProjectsState, ProjectsActions>
  >(projectsReducer, projectsInitState);

  const togglePhoto = (projectId: string, photoId: string, type: PhotoType) => {
    dispatchProjects({
      type: ActionTypes.TOGGLE_PHOTO_FOR_TIMELAPSE,
      payload: { projectId, photoId, type }
    });
  };

  const getPhotosForTimelapse = async (
    { newPhotos, allPhotos, id, slug }: Project,
    type: PhotoType
  ) => {
    const photosToProcess = type === 'NEW' ? newPhotos : allPhotos;
    if (photosToProcess && photosToProcess.length) {
      await Promise.all(
        photosToProcess.map(
          async photo =>
            await updatePhotoLabels(photo.id, photo.enabledForTimelapse)
        )
      );
      if (photosToProcess.every(photo => !photo.enabledForTimelapse)) {
        getNewPhotos(dispatchProjects);
      } else {
        fetchTimelapsePhotos(dispatchTimelapse, id, slug);
      }
    }
  };
  useEffect(() => {
    fetchAllProjects(dispatchProjects);
  }, []);

  useEffect(() => {
    if (
      projects.projects &&
      projects.projects.length > 0 &&
      !projects.newPhotosLoaded
    ) {
      getNewPhotos(dispatchProjects);
      getAllPhotos(dispatchProjects);
    }
  }, [projects.projects, projects.newPhotosLoaded]);

  useEffect(() => {
    if (
      timelapse.photosToProcess &&
      timelapse.photosToProcess.length > 0 &&
      timelapse.projectSlugToProcess
    ) {
      createTimelapse(
        dispatchTimelapse,
        transloaditOptions(
          timelapse.projectSlugToProcess,
          timelapse.photosToProcess
        )
      );
    }
  }, [timelapse.photosToProcess, timelapse.projectSlugToProcess]);

  useEffect(() => {
    if (
      timelapse.assembly &&
      timelapse.assembly.ok !== transloaditStatus.ASSEMBLY_COMPLETED
    ) {
      const getStatus = setInterval(
        () =>
          fetchAssemblyStatus(
            dispatchTimelapse,
            timelapse.assembly.assembly_id
          ),
        5000
      );
      return () => clearInterval(getStatus);
    } else if (
      timelapse.assembly &&
      timelapse.assembly.ok === transloaditStatus.ASSEMBLY_COMPLETED
    ) {
      dispatchTimelapse({ type: ActionTypes.CREATE_TIMELAPSE_READY });
    } else {
      dispatchTimelapse({ type: ActionTypes.CREATE_TIMELAPSE_ERROR });
    }
  }, [timelapse.assembly]);

  useEffect(() => {
    if (
      timelapse.timelapseIsReady &&
      timelapse.projectIdToProcess &&
      timelapse.assembly &&
      timelapse.assembly.results &&
      timelapse.assembly.results.merged
    ) {
      updateProject(
        dispatchProjects,
        timelapse.projectIdToProcess,
        timelapse.assembly.results.merged[0].ssl_url
      );
      setActiveProject(undefined);
      setSelectedTab(0);
      fetchAllProjects(dispatchProjects);
    }
  }, [
    timelapse.timelapseIsReady,
    timelapse.projectIdToProcess,
    timelapse.assembly
  ]);

  return (
    <Fragment>
      {timelapse.isLoading && (
        <Overlay message="Even geduld, de nieuwe timelapse wordt gemaakt, dit kan even duren..." />
      )}
      <Fragment>
        <PageTitle>Foto management</PageTitle>
        {!projects.newPhotosLoaded ? (
          <Overlay message="Nieuwe foto's worden opgehaald..." />
        ) : (
          <p>
            Kies een project om de nieuwe foto’s te bekijken of om de huidige
            timelapse aan te passen
          </p>
        )}
        <Projects>
          {projects.projects &&
            projects.projects.map(
              project =>
                (process.env.NODE_ENV === 'development' || project.enabled) && (
                  <ProjectPhotos
                    key={project.id}
                    project={project}
                    togglePhoto={togglePhoto}
                    getPhotosForTimelapse={getPhotosForTimelapse}
                    isLoading={timelapse.isLoading}
                    activeProject={activeProject}
                    setActiveProject={setActiveProject}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                  />
                )
            )}
        </Projects>
      </Fragment>
    </Fragment>
  );
};
