import moment from 'moment';
import { ProjectsActions } from '../actions/projects';
import { TimelapseActions } from '../actions/timelapse';
import { ActionTypes } from '../actions/actionTypes';
import { ProjectActions } from '../actions/project';
import { api } from '../utils/api';
import { logger } from '../utils/logger';
import { Project } from '../types/project';

const PHOTO_PATH = 'photos'

export const getNewPhotos = async (
  dispatch: React.Dispatch<ProjectsActions>
) => {
  dispatch({ type: ActionTypes.FETCH_NEW_PHOTOS });
  const response = await api({
    path: `${PHOTO_PATH}?new=true&_sort=created_at:ASC&_limit=-1`,
    method: 'GET'
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.FETCH_NEW_PHOTOS_ERROR });
    logger.error(`No new photos found`);
  } else {
    dispatch({
      type: ActionTypes.FETCH_NEW_PHOTOS_SUCCESS,
      payload: response.data
    });
  }
};

export const getAllPhotos = async (
  dispatch: React.Dispatch<ProjectsActions>
) => {
  dispatch({ type: ActionTypes.FETCH_ALL_PHOTOS });
  const response = await api({
    path: `${PHOTO_PATH}?timelapse=true&_sort=created_at:ASC&_limit=-1`,
    method: 'GET'
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.FETCH_ALL_PHOTOS_ERROR });
    logger.error(`No new photos found`);
  } else {
    dispatch({
      type: ActionTypes.FETCH_ALL_PHOTOS_SUCCESS,
      payload: response.data
    });
  }
};


export const storeImage = async (
  dispatch: React.Dispatch<ProjectActions>,
  project: Project,
  imageUrl: string,
  imageId: number
) => {
  dispatch({ type: ActionTypes.STORE_IMAGE, payload: imageUrl });
  const response = await api({
    path: PHOTO_PATH,
    method: 'POST',
    body: {
      title: `${project.slug}-${moment().format(
        'YYYY-MM-DDTHH-mm-ss-SSS'
      )}`,
      project: project.id,
      new: true,
      timelapse: false,
      url: imageUrl,
      file: imageId
    }
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.STORE_IMAGE_ERROR });
  } else {
    dispatch({
      type: ActionTypes.STORE_IMAGE_SUCCESS,
      payload: response.data
    });
  }
};


export const updatePhotoLabels = async (
  id: string,
  enabledForTimelapse: boolean
) => {
  const response = await api({
    path: `${PHOTO_PATH}/${id}`,
    method: 'PUT',
    body: {
      new: false,
      timelapse: enabledForTimelapse
    }
  });
  if (response.status !== 200 || response.error) {
    logger.error(`No photo labels were updated`);
  }
};

export const fetchTimelapsePhotos = async (
  dispatch: React.Dispatch<TimelapseActions>,
  projectId: string,
  projectSlug: string
) => {
  dispatch({ type: ActionTypes.FETCH_TIMELAPSE_PHOTOS });
  const response = await api({
    path: `${PHOTO_PATH}?title_contains=${projectSlug}&timelapse=true&_sort=created_at:ASC&_limit=-1`,
    method: 'GET'
  });
  if (
    response.status !== 200 ||
    response.error ||
    (response.data && response.data.length <= 0)
  ) {
    dispatch({ type: ActionTypes.FETCH_TIMELAPSE_PHOTOS_ERROR });
    logger.error(
      `No timelapse photo's for project '${projectSlug}' were found`
    );
  } else {
    dispatch({
      type: ActionTypes.FETCH_TIMELAPSE_PHOTOS_SUCCESS,
      payload: {
        photos: response.data,
        projectSlug: projectSlug,
        projectId: projectId
      }
    });
  }
};
