import React, { FC } from 'react';
import moment from 'moment';
import { Thumbnails, ThumbContainer } from './styles';
import { Checkbox } from '../../components/Checkbox';
import { LargeButton } from '../../components/Button';
import { Project } from '../../types/project';
import { PhotoType } from '../../types/photo';
import {
  CheckboxContainer,
  Thumbnail,
  Meta,
  ThumbnailsContainer
} from './styles';

interface Props {
  project: Project;
  onTogglePhoto: (projectId: string, photoId: string, type: PhotoType) => void;
  onCreateTimelapse: () => void;
  isLoading: boolean;
  type: PhotoType;
}

export const ProjectThumbnails: FC<Props> = ({
  project,
  onTogglePhoto: onDisable,
  onCreateTimelapse,
  isLoading,
  type
}) => {
  const amountOfPhotosForTimelapse = project.newPhotos
    ? project.newPhotos.filter(photo => photo.enabledForTimelapse).length
    : 0;
  const photosToShow = type === 'NEW' ? project.newPhotos : project.allPhotos;
  return (
    <ThumbnailsContainer>
      <Thumbnails>
        {photosToShow &&
          photosToShow.map(
            photo =>
              photo.photo &&
              photo.photo.id &&
              photo.photo.url && (
                <ThumbContainer
                  key={photo.id}
                  onClick={() => onDisable(project.id, photo.photo.id, type)}
                >
                  <CheckboxContainer>
                    <Checkbox
                      value={photo.enabledForTimelapse}
                      onChange={() => onDisable(project.id, photo.photo.id, type)}
                    />
                  </CheckboxContainer>
                  <Thumbnail enabled={photo.enabledForTimelapse} src={photo.photo.url} />
                  <Meta enabled={photo.enabledForTimelapse}>
                    {moment(photo.date).format('DD.MM.YY HH:mm')}
                  </Meta>
                </ThumbContainer>
              )
          )}
      </Thumbnails>
      <LargeButton
        onClick={onCreateTimelapse}
        disabled={isLoading || (!photosToShow || photosToShow.length === 0)}
      >
        {type === 'NEW'
          ? amountOfPhotosForTimelapse === 0
            ? `Wijzigingen opslaan`
            : `Maak timelapse (${amountOfPhotosForTimelapse} nieuwe foto${
                amountOfPhotosForTimelapse !== 1 ? `'s` : ``
              })`
          : 'Maak nieuwe timelapse'}
      </LargeButton>
    </ThumbnailsContainer>
  );
};
