import { Projects } from '../types/project';
import { ActionTypes } from '../actions/actionTypes';
import { ProjectsActions } from '../actions/projects';

export interface ProjectsState {
  projects?: Projects;
  isLoading: boolean;
  hasError: boolean;
  newPhotosLoaded: boolean;
  allPhotosLoaded: boolean;
}

export const reducer: React.Reducer<ProjectsState, ProjectsActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_PROJECTS:
      return { ...state, isLoading: true };
    case ActionTypes.FETCH_PROJECTS_ERROR:
      return { ...state, isLoading: false, hasError: true };
    case ActionTypes.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        projects: action.payload
      };
    case ActionTypes.FETCH_NEW_PHOTOS:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.FETCH_NEW_PHOTOS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        newPhotosLoaded: true
      };
    case ActionTypes.FETCH_NEW_PHOTOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        newPhotosLoaded: true,
        projects:
          state.projects &&
          state.projects.map(project => ({
            ...project,
            newPhotos: action.payload
              .filter(photo => photo.project.slug === project.slug)
              .map(photo => ({ ...photo, enabledForTimelapse: true }))
          }))
      };
    case ActionTypes.FETCH_ALL_PHOTOS:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.FETCH_ALL_PHOTOS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        allPhotosLoaded: true
      };
    case ActionTypes.FETCH_ALL_PHOTOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        allPhotosLoaded: true,
        projects:
          state.projects &&
          state.projects.map(project => ({
            ...project,
            allPhotos: action.payload
              .filter(photo => photo.project.slug === project.slug)
              .map(photo => ({ ...photo, enabledForTimelapse: true }))
          }))
      };
    case ActionTypes.TOGGLE_PHOTO_FOR_TIMELAPSE:
      const photosType =
        action.payload.type === 'NEW' ? 'newPhotos' : 'allPhotos';
      return {
        ...state,
        projects:
          state.projects &&
          state.projects.map(project => ({
            ...project,
            [photosType]:
              project.id === action.payload.projectId
                ? project[photosType] &&
                  project[photosType]!.map(photo => photo.id === action.payload.photoId
                      ? {
                          ...photo,
                          enabledForTimelapse: !photo.enabledForTimelapse
                        }
                      : photo
                  )
                : project[photosType]
          }))
      };
    case ActionTypes.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        newPhotosLoaded: false
      };

    default:
      return state;
  }
};

export const initState: ProjectsState = {
  isLoading: false,
  hasError: false,
  newPhotosLoaded: false,
  allPhotosLoaded: false
};
